import { useEffect } from "react";

import { Unity, useUnityContext } from "react-unity-webgl";
import { Helmet } from "react-helmet-async";

import styles from "./basketmines.module.scss";

const BasketMines = () => {
  useEffect(() => {
    document.title = "BasketMines | AlmostGames";
  }, []);

  const { unityProvider, isLoaded, loadingProgression } = useUnityContext({
    loaderUrl: "builds/basketMines/build.loader.js",
    dataUrl: "builds/basketMines/build.data",
    frameworkUrl: "builds/basketMines/build.framework.js",
    codeUrl: "builds/basketMines/build.wasm",
  });

  return (
    <div className={styles.container}>
      <Helmet>
        <meta
          name="apple-itunes-app"
          content="app-id=6499581909, app-argument=myURL"
        />
      </Helmet>
      {!isLoaded ? (
        <div className={styles.loading}>
          <h4>Loading... {Math.round(loadingProgression * 100)}%</h4>
          <div className={styles.outerBox}>
            <div
              className={styles.innerBox}
              style={{ width: loadingProgression * 100 + "%" }}
            />
          </div>
        </div>
      ) : null}
      <Unity
        unityProvider={unityProvider}
        devicePixelRatio={window.devicePixelRatio}
      />
    </div>
  );
};

export default BasketMines;
