import Footer from "../../components/footer";
import GameBox from "../../components/gameBox";
import styles from "./home.module.scss";

export default function Home() {
  return (
    <>
      <main className={styles.main}>
        <section className={styles.innerContainer}>
          <img src="bgLeft.svg" className={styles.bgLeft} alt="" />
          <img src="bgRight.svg" className={styles.bgRight} alt="" />
          <header>
            <div className={styles.social} />
            <img
              src="almost-logo.svg"
              className={styles.logo}
              alt="AlmostApps Logo"
            />
            <div className={styles.right} />
          </header>
          <div className={styles.row}>
            <img src="rectangles.svg" className={styles.rectangles} alt="" />
            <h1>Discover our games</h1>
            <img src="rectangles.svg" className={styles.rectangles} alt="" />
          </div>
          <div className={styles.grid}>
            <GameBox
              name="Tailgate"
              image="/tailgate.png"
              url="https://testflight.apple.com/join/qVXsFfDn"
              appStore="https://testflight.apple.com/join/qVXsFfDn"
            />
            <GameBox
              name="BasketBOMB"
              image="/basketBomb.png"
              url="/basket-bomb"
              appStore="https://apps.apple.com/ca/app/basketbomb/id6499581909"
              googlePlay="https://play.google.com/store/apps/details?id=com.almostapps.basketbomb"
            />
            <GameBox
              name="FootballRush"
              image="/footballRush.png"
              url="/football-rush"
              appStore="https://apps.apple.com/ca/app/almost-football-rush/id6499539702"
              googlePlay="https://play.google.com/store/apps/details?id=com.almostapps.footballrush"
            />
            <GameBox
              name="BasketMines"
              image="/basketMines.png"
              url="/basket-mines"
            />
            <GameBox
              name="Shot Or Not"
              image="/shotOrNot.png"
              url="https://shotornot.xyz/"
            />
            <GameBox
              name="Super Pick Sunday"
              image="/sps.png"
              url="https://superpicksunday.com/"
            />
            <GameBox
              name="Buzzer Beater Pickem"
              image="/buzzerBeater.png"
              url="https://buzzerbeatergame.com/"
            />
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
