import React from "react";

import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Home from "./routes/home";
import BasketBomb from "./routes/basketBomb";
import BasketMines from "./routes/basketMines";
import SecretGame from "./routes/secretGame";
import FootballRush from "./routes/footballRush";

import "./styles/globals.scss";
import mixpanel from "mixpanel-browser";
import { HelmetProvider } from "react-helmet-async";

mixpanel.init(process.env.REACT_APP_MIXPANEL_API!, { track_pageview: true });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/basket-bomb",
    element: <BasketBomb />,
  },
  {
    path: "/basket-mines",
    element: <BasketMines />,
  },
  {
    path: "/football-rush",
    element: <FootballRush />,
  },
  {
    path: "/secret-game",
    element: <SecretGame />,
  },
]);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  </React.StrictMode>
);
