import styles from "./footer.module.scss";

const Footer: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.row}>
        <img
          src="almost-logo.svg"
          className={styles.logo}
          alt="AlmostApps Logo"
        />
        <div className={styles.navigation}>
          <a href="/">Home</a>
          <a href="https://almostapps.com/terms-of-service.pdf">
            Terms of Service
          </a>
          <a href="https://almostapps.com/privacy-policy.pdf">Privacy Policy</a>
        </div>
        <div className={styles.social} />
      </div>
      <p>© 2024 Almost Apps LLC. All right reserved.</p>
    </footer>
  );
};

export default Footer;
