import { Link } from "react-router-dom";

import styles from "./gameBox.module.scss";

interface Props {
  name: string;
  url: string;
  image: string;
  appStore?: string;
  googlePlay?: string;
}

const GameBox: React.FC<Props> = ({
  image,
  name,
  url,
  googlePlay,
  appStore,
}) => {
  return (
    <div className={styles.box}>
      <div className={styles.inner}>
        <img src={image} alt={name} />
        {url !== "" ? (
          <Link to={url}>
            <div className={styles.now}>
              <div className={styles.inner}>
                <h3>PLAY NOW</h3>
              </div>
            </div>
          </Link>
        ) : null}
        {googlePlay || appStore ? (
          <div className={styles.downloads}>
            {googlePlay ? (
              <a
                href={googlePlay}
                className={styles.container}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.google}>
                  <img
                    src="/playNew.png"
                    alt={`Google Play download link for ${name}`}
                    className={styles.download}
                  />
                </div>
              </a>
            ) : null}
            {appStore ? (
              <a
                className={styles.container}
                href={appStore}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.apple}>
                  <img
                    src="/appStoreNew.png"
                    alt={`AppStore download link for ${name}`}
                    className={styles.download}
                  />
                </div>
              </a>
            ) : null}
          </div>
        ) : (
          <div className={styles.soon}>
            <h3>COMING SOON...</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default GameBox;
